<template>
  <div>
    <div
      v-for="group in groups"
      :key="group._id"
      class="mb-3"
      v-if="group.markets.length"
    >
      <span class="text-primary">{{group.groupTitle}}</span>
      <div>
        <b-form-checkbox v-for="metket in group.markets"
          :key="metket._id"
          v-model="selectedMarketIds"
          :value="metket._id"
          inline
        >
          {{metket.marketTitle}}
        </b-form-checkbox>
      </div>
    </div>

    <div class="pt-2">
      <button type="button" class="btn btn-sm btn-primary mr-1 mb-1" @click="selectAll"><i class="fas fa-check align-middle"></i> เลือกทั้งหมด</button>
      <button type="button" class="btn btn-sm btn-warning mr-1 mb-1" @click="unSelectAll"><i class="fas fa-times"></i> เอาออกทั้งหมด</button>
      <button v-for="group in groups" :key="group._id" v-if="group.markets.length" type="button" class="btn btn-sm btn-info mr-1 mb-1" @click="select(group._id)"><i class="fas fa-check align-middle"></i> {{group.groupTitle}}</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MarketFilter',
  props: ['marketIds'],
  data() {
    return {
      selectedMarketIds: []
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups.map((group)=>{
        group.markets = this.$store.state.markets.filter((market)=>{
          return group._id === market.groupId
        })
        return group
      })
    },
    markets() {
      return this.$store.state.markets
    }
  },
  watch: {
    selectedMarketIds() {
      this.$emit('submit', this.selectedMarketIds)
    },
    marketIds() {
      this.selectedMarketIds = this.marketIds
    }
  },
  methods: {
    select(groupId) {
      this.selectedMarketIds = this.markets.filter((market)=>{
        return market.groupId === groupId
      })
      .map((market)=>{
        return market._id
      })
    },
    selectAll() {
      this.selectedMarketIds = this.markets.map((market)=>{
        return market._id
      })
    },
    unSelectAll() {
      this.selectedMarketIds = []
    }
  }
}
</script>
